<template>
    <div class="home">
        <div>
            <img src="@/assets/logo-white.svg" alt="EasyMP" />
        </div>
        <div class="help">Система управления маркетплейсами</div>
        <div class="links">
            <div>
                <a class="button" href="https://auth.easymp.ru">Войти</a>
            </div>
            <!--            <div>
                <a class="link" href="https://auth.easymp.ru">Войти</a>
            </div>-->
        </div>
    </div>
</template>

<script>
export default {
    name: "HomeView",
};
</script>

<style>
body {
    padding: 0;
    margin: 0;
}
</style>

<style lang="scss" scoped>
.home {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;

    img {
        height: 250px;
    }

    .help {
        margin-top: 15px;
        font-size: 0.9vw;
        color: #fff;
    }

    .links {
        margin-top: 50px;
        font-size: 1vw;
        font-weight: 400;
        text-align: center;

        .button {
            background: #fff;
            text-decoration: none;
            color: #000;
            border-radius: 15px;
            padding: 15px 40px;
            display: block;
            transition: 200ms;
            box-shadow: 0 0 0 0 #c4c4c4;
            &:hover {
                text-decoration: none;
                background: #fff;
                transform: translateY(-5px);
                box-shadow: 0 5px 0 0 #c4c4c4;
            }
        }

        .link {
            color: #fff;
            display: inline-block;
            margin-top: 15px;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
</style>
